import React, { Component } from "react";
import { Modal as BootstrapModal } from "../../utils";
import Close from "../../images/close-2.svg";
import "./modal.css";

class Modal extends Component {
  render() {
    const { show, closeModal, children, modalClass = "" } = this.props;

    return (
      <BootstrapModal
        show={show}
        bsClass="custom-modal modal"
        backdropClassName="custom-modal-backdrop"
        animation={false}
        onHide={closeModal}
        dialogClassName={modalClass}
      >
        <BootstrapModal.Header bsClass="custom-modal-header">
          <BootstrapModal.Title bsClass="custom-modal-title">
            <img onClick={closeModal} src={Close} alt="" />
          </BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body bsClass="custom-modal-body">
          {children}
        </BootstrapModal.Body>
      </BootstrapModal>
    );
  }
}

export default Modal;
