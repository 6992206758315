import React from "react";
import { Navbar } from "../../utils/bootstrap";
import logo from "../../images/header-landing-pages-logo.svg";
import burgur from "../../images/header-landing-pages-menu.svg";
import close from "../../images/landing-page-menu-close.svg";

import "./stepsNavbar.css";

const StepsNavbar = ({
  history,
  menuOpen = false,
  menuOnClick,
  hasStartNav = true
}) => {
  return (
    <Navbar fluid fixedTop collapseOnSelect bsStyle="incola-steps">
      <Navbar.Header>
        <Navbar.Brand>
          {!menuOpen ? (
            <img
              src={burgur}
              alt="menu"
              className="pointer nav-burger"
              onClick={menuOnClick}
            />
          ) : (
            <img
              src={close}
              alt="close menu"
              className="pointer nav-burger"
              onClick={menuOnClick}
            />
          )}
        </Navbar.Brand>
        <Navbar.Brand>
          <img
            src={logo}
            alt="incola"
            className="pointer"
            onClick={() => history.push("/")}
          />
        </Navbar.Brand>
      </Navbar.Header>
    </Navbar>
  );
};

export default StepsNavbar;
