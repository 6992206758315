import React, { Component, Fragment } from "react";
import StepsWrapper from "../steps/stepsWrapper";
import { Col } from "../../utils/bootstrap";
import ERROR_ICON from "../../images/error.svg";
import "./error.css";

class PaypalCancelComponent extends Component {
  render() {
    return (
      <Fragment>
        <StepsWrapper history={this.props.history}>
          <Col lg={12} className="text-center error-wrapper">
            <h1>Transaction cancelled</h1>
            <h5>Please try again or contact us for help.</h5>
            <img className="margin-50" src={ERROR_ICON} alt="Cancelled" />
            <div className="margin-50" />
            <h5>
              Please check out our{" "}
              <a href="https://incola.com.au/faq">FAQ page</a> to answer any
              question you have or check out{" "}
              <a
                href={
                  "https://www.paypal.com/au/smarthelp/article/what-is-paypal-pay-in-4-faq4333"
                }
              >
                PayPals FAQ page
              </a>
              .<br />
              If you need further help from us please contact
              support@incola.com.au
            </h5>
          </Col>
        </StepsWrapper>
      </Fragment>
    );
  }
}

export default PaypalCancelComponent;
