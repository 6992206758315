import React from "react";
import { Col } from "../../utils/bootstrap";
import info from "../../images/post-signup-info.svg";

import "./information.css";

const Information = ({
  text = false,
  width = 12,
  classes = "",
  noImage = false,
  children
}) => {
  return (
    <Col lg={width} className={`information-field ${classes}`}>
      <p>
        {noImage ? null : (
          <img src={info} alt="" className="information-icon" />
        )}
        {text || children}
      </p>
    </Col>
  );
};

export default Information;
