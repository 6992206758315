import React, { Component, Fragment } from "react";
import StepsWrapper from "../steps/stepsWrapper";
import { Col } from "../../utils/bootstrap";
import THANKYOU_ICON from "../../images/icon-smile.svg";
import "./error.css";

class PaypalSuccessComponent extends Component {
  render() {
    return (
      <Fragment>
        <StepsWrapper history={this.props.history}>
          <Col lg={12} className="text-center error-wrapper">
            <h1>Your transaction was successful.</h1>
            <h5>
              You will receive an email from paypal shortly with transaction
              details.
            </h5>

            <img className="margin-50" src={THANKYOU_ICON} alt="Success" />
          </Col>
        </StepsWrapper>
      </Fragment>
    );
  }
}

export default PaypalSuccessComponent;
