import { lazy } from "react";
import PaypalCancelComponent from "../components/pay-with-paypal/cancelled";
import PaypalErrorComponent from "../components/pay-with-paypal/error";
import NotFoundComponent from "../components/pay-with-paypal/notFound";
import PaypalSuccessComponent from "../components/pay-with-paypal/thankyou";
const LandingPage = lazy(() =>
  import("../components/home/landingPage/landingPage")
);
const HowItWorks = lazy(() => import("../components/home/howItWorks"));
const AboutUs = lazy(() => import("../components/home/aboutUs"));
const FAQ = lazy(() => import("../components/home/faq"));
const Steps = lazy(() => import("../components/steps/steps"));
const ThankYou = lazy(() => import("../components/thankyou/thankyou"));
const ThankYouMyConnect = lazy(() =>
  import("../components/thankyou/thankyoumyconnect")
);
const PendingApproval = lazy(() =>
  import("../components/thankyou/pendingApproval")
);
const ErrorComponent = lazy(() => import("../components/error/error"));
const CancelComponent = lazy(() => import("../components/error/cancelled"));
const ErrorStateComponent = lazy(() =>
  import("../components/error/errorState")
);
const ZipRedirect = lazy(() => import("../components/steps/zipRedirect"));
const ZipProcessing = lazy(() => import("../components/steps/zipProcessing"));
const PrivacyPolicy = lazy(() =>
  import("../components/privacy-policy/privacyPolicy")
);
const Terms = lazy(() => import("../components/terms/terms"));
const Accessibility = lazy(() =>
  import("../components/accessibility-policy/accessibility")
);
const Community = lazy(() =>
  import("../components/community-guidlines/community")
);
const Disclaimer = lazy(() => import("../components/disclaimer/disclaimer"));
const Application = lazy(() =>
  import("../components/application-form/applicationForm")
);

const Confirm = lazy(() => import("../components/thankyou/confirm"));
const SendEmail = lazy(() => import("../components/sendEmail/sendEmail"));
const PayWithPaypal = lazy(() =>
  import("../components/pay-with-paypal/payWithPaypal")
);

const NewAbout = lazy(() => import("../containers/newAbout/AboutPage"));
const AlternativeOptions = lazy(() =>
  import("../containers/alternativeOptions/AlternativeOptions")
);

export const ROUTES = [
  {
    path: "/",
    component: LandingPage,
    title: "Making moving easier for renters"
  },
  {
    path: "/how-it-works",
    component: HowItWorks,
    title: "How it Works - Incola"
  },
  {
    path: "/about-us",
    component: NewAbout,
    title: "About Us - Incola"
  },
  {
    path: "/faq",
    component: FAQ,
    title: "FAQ - Incola"
  },
  {
    path: "/application",
    component: Application,
    title: "Application - Incola"
  },
  {
    path: "/confirm",
    component: Confirm,
    title: "Application Confirmation - Incola"
  },
  {
    path: "/process-bond-application",
    component: Steps,
    title: "Sign up - Incola"
  },
  {
    path: "/thankyou",
    component: ThankYou,
    title: "Submitted - Incola"
  },
  {
    path: "/thank-you",
    component: ThankYouMyConnect,
    title: "Thank You - Incola"
  },
  {
    path: "/pending-approval",
    component: PendingApproval,
    title: "Pending Approval - Incola"
  },
  {
    path: "/send-email",
    component: SendEmail,
    title: "Send Email - Incola"
  },
  {
    path: "/redirect",
    component: ZipRedirect,
    title: "Incola"
  },
  {
    path: "/processing",
    component: ZipProcessing,
    title: "Incola"
  },
  {
    path: "/processing/:state",
    component: ZipProcessing,
    title: "Incola"
  },
  {
    path: "/error",
    component: ErrorComponent,
    title: "Incola"
  },
  {
    path: "/cancel",
    component: CancelComponent,
    title: "Incola"
  },
  {
    path: "/error/:state",
    component: ErrorStateComponent,
    title: "Incola"
  },
  {
    path: "/privacy",
    component: PrivacyPolicy,
    title: "Privacy Policy - Incola"
  },
  {
    path: "/terms",
    component: Terms,
    title: "Terms - Incola"
  },
  {
    path: "/community",
    component: Community,
    title: "Community - Incola"
  },
  {
    path: "/accessibility",
    component: Accessibility,
    title: "Accessibility - Incola"
  },
  {
    path: "/disclaimer",
    component: Disclaimer,
    title: "Disclaimer - Incola"
  },
  {
    path: "/pay-with-paypal",
    component: NotFoundComponent,
    title: "Pay With Paypal - Incola"
  },
  {
    path: "/pay-with-paypal/:transactionId",
    component: PayWithPaypal,
    title: "Pay With Paypal - Incola"
  },
  {
    path: "/paypal/cancelled",
    component: PaypalCancelComponent,
    title: "Transaction Cancelled - Incola"
  },
  {
    path: "/paypal/error",
    component: PaypalErrorComponent,
    title: "Error - Incola"
  },
  {
    path: "/paypal/success",
    component: PaypalSuccessComponent,
    title: "Success - Incola"
  },
  {
    path: "/404",
    component: NotFoundComponent,
    title: "Page not Found - Incola"
  },
  {
    path: "/alternative-options",
    component: AlternativeOptions,
    title: "Alternative Options"
  }
];

export const DEFAULT_TITLE = "Making moving easier for renters";
