import React, { Component } from "react";

import "./input.css";

class Input extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.focus === false && this.props.focus === true) {
      this.inputRef.current.focus();
    }
  }

  render() {
    const {
      placeholder,
      value,
      classes,
      inputChange,
      autofocus,
      type,
      error,
      onKeyPress,
      disabled,
      name
    } = this.props;

    return (
      <input
        type={type}
        name={name || ""}
        value={value}
        placeholder={placeholder}
        className={`input ${classes}${error ? " input-error" : ""}`}
        onChange={inputChange}
        autoFocus={autofocus}
        onKeyPress={onKeyPress}
        disabled={disabled}
        ref={this.inputRef}
      />
    );
  }
}

Input.defaultProps = {
  type: "text",
  placeholder: "Enter Text",
  value: "",
  classes: "",
  inputChange: () => {},
  autofocus: false,
  error: false,
  disabled: false,
  focus: false
};

export default Input;
