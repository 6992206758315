import { Component } from "react";
import { ROUTES, DEFAULT_TITLE } from "./routeList";

class TitleMapper extends Component {
  componentDidMount() {
    const title = this.findTitle(
      this.props.location.pathname,
      this.props.location.search
    );

    document.title = title;
  }

  findTitle = pathname => {
    let windowTitle = DEFAULT_TITLE;

    ROUTES.forEach(({ path, title }) => {
      if (path === pathname) {
        windowTitle = title;
      }
    });

    return windowTitle;
  };

  render() {
    return null;
  }
}

export default TitleMapper;
