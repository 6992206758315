import FbIcon from "../images/menu-landing-pages-facebook.svg";
import InstaIcon from "../images/menu-landing-pages-instagram.svg";
import TwitterIcon from "../images/menu-landing-pages-twitter.svg";

import FbIconPS from "../images/footer-post-signup-facebook.svg";
import InstaIconPS from "../images/footer-post-signup-instagram.svg";
import TwitterIconPS from "../images/footer-post-signup-twitter.svg";

export const BASIC_SIGNUP_STEPS = [
  {
    text: "Hey! it’s Julia. Let’s get started.",
    back: false,
    buttonText: "Next"
  },
  {
    text: "Where are you going to live?",
    back: true,
    buttonText: "Next"
  },
  {
    text: "How much is your bond?",
    back: true,
    buttonText: "Next"
  },
  {
    text: "Income",
    back: true,
    buttonText: "Next"
  },
  {
    text: "Estimated payment schedule.",
    back: true,
    buttonText: "Next"
  },
  {
    text: "Mobile Number",
    back: true,
    buttonText: "Next"
  }
];

export const SIGNUP_STEPS = [
  ...BASIC_SIGNUP_STEPS,
  {
    text: "Sign up",
    back: true,
    buttonText: "Sign Up"
  }
];

export const NEW_APPLICATION_SIGNUP_STEPS = [...BASIC_SIGNUP_STEPS];

export const LOGGED_IN_MENU = [
  {
    name: "Home",
    link: "/"
  },
  {
    name: "separation",
    link: "/"
  },
  {
    name: "How it Works",
    link: "/how-it-works"
  },
  {
    name: "FAQ",
    link: "/faq"
  },
  {
    name: "About",
    link: "/about-us"
  },
  {
    name: "separation",
    link: "/"
  },
  {
    name: "For Agencies",
    link: "/agencies"
  },
  {
    name: "separation",
    link: "/"
  }
];

export const MENU_PRE_SIGNUP = [
  ...LOGGED_IN_MENU,
  {
    name: "Log in",
    link: "/login"
  }
];

export const MENU_POST_SIGNUP = [
  ...LOGGED_IN_MENU,
  {
    name: "Log out",
    link: "/logout"
  }
];

export const HOME_MENU = [
  {
    name: "How it Works",
    link: "/how-it-works"
  },
  {
    name: "Get Started",
    link: "/application"
  },

  {
    name: "FAQ",
    link: "/faq"
  },
  {
    name: "separation",
    link: "/"
  },
  {
    name: "About",
    link: "/about-us"
  },
  {
    name: "Alternative Options",
    link: "/alternative-options"
  },

  {
    name: "Terms & Conditons ",
    link: "/terms"
  },
  // {
  //   name: "Privacy Policy",
  //   link: "/privacy"
  // },
  {
    name: "separation",
    link: "/"
  },
  {
    name: "Apply",
    link: "#",
    type: "redirect"
  }

  // {
  //   name: "Log in",
  //   link: "/login"
  // },
  // {
  //   name: "separation",
  //   link: "/"
  // },
  // {
  //   name: "Ask Questions",
  //   link: "#",
  //   type: "function"
  // }
];

export const FOOTER_MENU = [
  {
    name: "Facebook",
    icon: FbIcon,
    link: "https://www.facebook.com/incola.it/"
  },
  {
    name: "Instagram",
    icon: InstaIcon,
    link: "https://www.instagram.com/incolabond/"
  },
  {
    name: "Twitter",
    icon: TwitterIcon,
    link: "https://twitter.com/Incola_aus"
  }
];

export const FOOTER_MENU_LOGGEDIN = [
  {
    name: "Facebook",
    icon: FbIconPS,
    link: "https://www.facebook.com/incola.it/"
  },
  {
    name: "Instagram",
    icon: InstaIconPS,
    link: "https://www.instagram.com/incolabond/"
  },
  {
    name: "Twitter",
    icon: TwitterIconPS,
    link: "https://twitter.com/Incola_aus"
  }
];

export const GOOGLE_MAPS_LINK =
  "https://maps.googleapis.com/maps/api/staticmap?";
export const GOOGLE_PLACES_LINK =
  "https://maps.googleapis.com/maps/api/place/details/json?fields=geometry,address_components";
export const GOOGLE_AUTOCOMPLETE_LINK =
  "https://maps.googleapis.com/maps/api/place/autocomplete/json?types=address";
export const GOOGLE_MAPS_KEY = "AIzaSyAmwA8tw1ZXG8fo16T3ymx2HY3Q1gw6dwU";

export const SERVICE_URL = "https://api.incola.com.au/api/v2/";
export const ESERVICE_URL = "https://service.incola.com.au/";
// export const ESERVICE_URL = "http://localhost:8000/";

export const ZIP_URL_SANDBOX =
  "https://api.sandbox.zipmoney.com.au/merchant/v1/";

export const FAQS = [
  {
    q: "What states does Incola operate in?",
    a:
      "We are available in New South Wales, Queensland, Victoria, South Australia and Australian Capital Territory."
  },
  {
    q: "What does Incola do?",
    a:
      "Incola is a one of kind solution that helps the average Australian move. We know moving can be a stressful situation and we allow you to first connect your utilities, we offer everything from AGL to Red Energy, Internet to Electricity. Upon connecting we link you with our partner where we offer you an interest-free loan for 6 weeks on your rental bond up to $1500."
  },
  {
    q: "How long does the process take?",
    a:
      "We ask that from connecting your utilities to getting your bond paid we ask to give at least 72 hours before you move. The process will take about 30 minutes."
  },
  {
    q: "Queries regarding repayments",
    a: "Repayments are done all through our finance partner Paypal."
  },
  {
    q: "Do I have to connect 2 utilities?",
    a:
      "Yes, to make our business viable to the public we ask you to connect 2 utilities - we give you full options and don't ask you to choose one provider over another. If you don't get a message for finance, it'll be because 2 utilities were not connected."
  },
  {
    q: "I’ve connected – next steps?",
    a:
      "Great! You'll then get a text/email from our team asking for your rental bond amount - please respond and you'll then receive our link to get finance."
  },
  {
    q: "Paying the rental bond – what does Incola need?",
    a:
      "Once accepted for finance, Incola needs your rental bond BPay details - this includes the biller code and reference number which are specific to your move."
  },
  {
    q: "Support and customer service?",
    a:
      "Incola is available between 9-5 pm Monday through Friday by email at support@incola.com.au and We aim to respond within 1 business day."
  },
  {
    q: "What if I don’t get approved for finance?",
    a:
      "Incola can look at other options for you if you don't get approved for finance."
  },
  // {
  //   q: "Are there any fees involved?",
  //   a:
  //     "There are zero fees to connect your utilities and a one-off fee of $50 if you get approved for finance."
  // },
  {
    q: "Does Incola pay my bond straight to the rental bond board?",
    a: "Yes, we do."
  },
  {
    q: "What are the minimum and maximum rental bonds you pay?",
    a: "Incola pays bonds up to $1500."
  },
  {
    q: "How quickly can you pay my bond?",
    a:
      "As soon as you have gone through our whole process, your bond will be paid within the hour and you will receive a remittance."
  },
  {
    q: "I’m on Centrelink – will I get approved?",
    a:
      "You will not get approved unless you have other income streams coming in."
  },
  {
    q: "Do I have to tell my real estate agent I’m using Incola?",
    a:
      "No, you don't! We simply pay the bond on your behalf so no need to tell them!"
  },
  {
    q: "Can you pay straight to my real estate?",
    a: "In some cases, we do - please contact us on support@incola.com.au"
  },
  {
    q: "What utilities can I connect?",
    a: "You can connect Internet, Gas, Water, Electricity, PayTv"
  },
  {
    q: "Can my partner do the finance & I connect the utilities?",
    a: "Yes, they can!"
  },
  // {
  //   q: "What happens if I get declined by MoneyMe?",
  //   a:
  //     "If you are declined, please contact MoneyMe directly 1300329037 and quote your application number."
  // },
  {
    q: "Once approved do I receive the cash?",
    a:
      "No, we either pay straight to the rental bond board or provided we have a tenancy agreement we pay to the real estate."
  },
  {
    q:
      "Do I have to have be a Permanant Resident or Citizen of Australia to apply?",
    a: "Yes, we don’t accept finance for anyone that isn’t a PR or citizen."
  }
];
