import React, { Component } from "react";
import { Grid, Row, Col } from "../../utils/bootstrap";
import { FOOTER_MENU } from "../../utils/constants";
import LazyLoad from "react-lazyload";
import "./homeMenu.css";

class HomeMenu extends Component {
  renderMenu = () => {
    const { menu, history, toggleModal, showRedirectModal } = this.props;

    return menu.map(({ name, link, type }) => {
      if (name === "separation") {
        return (
          <Col lg={12}>
            <hr className="home-menu-separate" />
          </Col>
        );
      } else if (type === "function") {
        return (
          <Col lg={12} className="menu-link" onClick={() => toggleModal()}>
            {name}
          </Col>
        );
      } else if (type === "redirect") {
        return (
          <Col lg={12} className="menu-link" onClick={showRedirectModal}>
            {name}
          </Col>
        );
      } else {
        return (
          <Col lg={12} className="menu-link" onClick={() => history.push(link)}>
            {name}
          </Col>
        );
      }
    });
  };

  renderFooter = () => {
    return FOOTER_MENU.map(({ name, link, icon }) => {
      return (
        <a href={link} target="_blank" rel="noopener noreferrer">
          <LazyLoad>
            <img
              src={icon}
              alt={name}
              className="pointer footer-social-links"
            />
          </LazyLoad>
        </a>
      );
    });
  };

  render() {
    const { show } = this.props;
    return (
      <div>
        <div className={`home-menu${show ? " home-menu-open" : ""}`}>
          <Grid>
            <Row>
              <Col lg={12} className="hidden-xs">
                <hr className="home-menu-separate" />
              </Col>
              <this.renderMenu />
            </Row>
            <div className="social-links hidden-xs">
              <div>
                <this.renderFooter />
              </div>
            </div>
          </Grid>
        </div>
      </div>
    );
  }
}

export default HomeMenu;
