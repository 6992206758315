import React, { Component, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { ROUTES } from "./routeList";
import TitleMapper from "./titleMapper";
import { uuidv4 } from "../utils";
import Loader from "../common/loader/loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class Routes extends Component {
  parseQueryParams = (query = "") => {
    try {
      if (query) {
        const queryParams = query.replace("?", "");
        const params = queryParams.split("&");
        const paramsObject = params.reduce((acc, param) => {
          const splitParam = param.split("=");
          const key = splitParam.shift();
          return {
            ...acc,
            [key]: splitParam.join("=")
          };
        }, {});

        return paramsObject;
      }

      return false;
    } catch (e) {
      console.error(e);
    }

    return false;
  };

  componentWillMount() {
    // campaignid=1641026125&adgroupid=62285473123&keyword=&device=c
    try {
      const queryParams = this.parseQueryParams(window.location.search);

      if (!queryParams) {
        return false;
      }

      const { adgroupid, campaignid, device, keyword } = queryParams;

      if (!adgroupid && !campaignid && !device && !keyword) {
        return false;
      }

      const gawRaw = localStorage.getItem("gaw");
      let gaw = gawRaw ? JSON.parse(gawRaw) : "";

      if (gaw && Array.isArray(gaw)) {
        gaw.push({
          adgroupid,
          campaignid,
          device,
          keyword,
          timestamp: Date.now()
        });
      } else {
        gaw = [
          {
            adgroupid,
            campaignid,
            device,
            keyword,
            timestamp: Date.now()
          }
        ];
      }
      localStorage.setItem("gaw", JSON.stringify(gaw));
    } catch (e) {
      console.error(e);
    }
  }

  shouldComponentUpdate() {
    return true;
  }

  render() {
    const toastOptions = {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      newestOnTop: false,
      closeOnClick: true,
      rtl: false,
      pauseOnFocusLoss: true,
      draggable: true,
      pauseOnHover: true
    };
    return (
      <div>
        <Route component={TitleMapper} />
        <Suspense fallback={<Loader />}>
          <ToastContainer {...toastOptions} />
          <Switch>
            {ROUTES.map(({ path, component }) => {
              return (
                <Route key={uuidv4()} exact path={path} component={component} />
              );
            })}
          </Switch>
        </Suspense>
      </div>
    );
  }
}

export default Routes;
