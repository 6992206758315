export const isAlphanumeric = input => {
  return /^[a-z0-9]+$/i.test(input);
};

export const hasNumbers = input => {
  return /\d/.test(input);
};

export const hasOnlyNumbers = input => {
  return !/[^0-9]/i.test(input);
};

export const hasOnlyLetters = input => {
  return !/[^a-z]/i.test(input);
};

export const checkLessThan = (input, number) => {
  if (hasOnlyNumbers(input)) {
    return input < number;
  }
  return false;
};

export const checkGreaterThan = (input, number) => {
  if (hasOnlyNumbers(input)) {
    return input > number;
  }

  return false;
};

export const checkRange = (input, numberLess, numberLarge) => {
  if (hasOnlyNumbers(input)) {
    return input < numberLarge && input > numberLess;
  }
  return false;
};

export const numberWithCommas = x => {
  let withoutComma = numberWithoutCommas(x);
  if (!Number(withoutComma)) {
    withoutComma = numberWithoutCommas(x.substr(0, x.length - 1));
  } else if (!x) {
    return "";
  }
  const num = Number(withoutComma);
  return num.toLocaleString();
};

export const numberWithoutCommas = x => {
  return x.toString().replace(/,/g, "");
};

export const validateEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const spacesBetween = value => {
  value = value.toString();
  return value.replace(/(\d{3})/g, "$1 ").replace(/(^\s+|\s+$)/, "");
};
