import React, { Component } from "react";
import Help from "./help";
import Modal from "../../common/modal/modal";

import "./helpModal.css";

class HelpModal extends Component {
  render() {
    const { show, closeModal } = this.props;

    return (
      <Modal show={show} closeModal={closeModal}>
        <Help closeModal={closeModal} />
      </Modal>
    );
  }
}

export default HelpModal;
