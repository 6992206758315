import React, { Component } from "react";
import { Col, Row, Button, FormControl } from "../../utils/bootstrap";
// import { connect } from "react-redux";

import Information from "../../common/information/information";
// import { askQuestion } from "../../actions/applicationAction";
import Input from "../../common/input/input";
import { validateEmail } from "../../utils/validation";

class Help extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: "",
      sentSuccess: false
    };
  }

  onChangeHandler = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  onSave = () => {
    const { closeModal, askQuestion } = this.props;
    const { name, email, message } = this.state;

    askQuestion(name, email, message);
    this.setState({
      sentSuccess: true
    });

    if (closeModal) {
      closeModal();
    }
  };

  render() {
    const { header, subHeader, textAreaPlaceholder } = this.props;
    const { name, email, message, sentSuccess } = this.state;

    return (
      <Row>
        <Col lg={12} className="text-center">
          <h4>{header || "Got a question?"}</h4>
          <h6>
            {subHeader ||
              "Let us know your question, we’ll get back to you as soon as possible."}
          </h6>
        </Col>
        <Col lg={12} className="text-center">
          <Input
            value={name}
            placeholder={"Full Name"}
            classes={`input-large margin-25`}
            inputChange={event =>
              this.onChangeHandler("name", event.target.value)
            }
          />
        </Col>
        <Col lg={12} className="text-center">
          <Input
            value={email}
            placeholder={"Email"}
            classes={`input-large margin-25`}
            inputChange={event =>
              this.onChangeHandler("email", event.target.value)
            }
          />
        </Col>
        <Col lg={12} className="text-center">
          <FormControl
            value={message}
            componentClass="textarea"
            placeholder={textAreaPlaceholder || "What’s confusing?"}
            bsClass="custom-textarea margin-25"
            onChange={event =>
              this.onChangeHandler("message", event.target.value)
            }
          />
        </Col>
        <Col lg={12} className="text-center margin-b25">
          <Button
            bsStyle="primary"
            bsClass="primary-btn"
            onClick={this.onSave}
            disabled={
              !name ||
              !message ||
              !email ||
              name.length > 20 ||
              message.length > 300 ||
              !validateEmail(email)
            }
          >
            Send
          </Button>
        </Col>
        {sentSuccess ? (
          <Information
            classes={"text-center"}
            text={
              "Thank you! One of our business development managers will get in touch with you."
            }
          />
        ) : null}
      </Row>
    );
  }
}

// const mapDispatchToProps = dispatch => ({
//   askQuestion: (name, email, message) =>
//     dispatch(askQuestion(name, email, message))
// });

// export default connect(
//   null,
//   null
//   // mapDispatchToProps
// )(Help);

export default Help;
