import React, { Component } from "react";

import StepsNavbar from "../../common/steps-navbar/stepsNavbar";
import HomeMenu from "../home-menu/homeMenu";
import { HOME_MENU } from "../../utils/constants";
import HelpModal from "../help-modal/helpmodal";

class StepsWrapper extends Component {
  constructor() {
    super();
    this.state = {
      checked: false,
      showTerms: false,
      scrolltop: false,
      menuOpen: false,
      showModal: false
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  onMenuClick = () => {
    this.setState(prevState => ({
      menuOpen: !prevState.menuOpen,
      scrolltop: !prevState.menuOpen ? false : !!window.scrollY
    }));
  };

  toggleModal = () => {
    this.setState(prevState => ({
      showModal: !prevState.showModal,
      menuOpen: false
    }));
  };

  render() {
    const { history, children, path } = this.props;
    const { scrolltop, menuOpen, showModal } = this.state;

    return (
      <div>
        <StepsNavbar
          path={path}
          history={history}
          scrolltop={scrolltop}
          menuOpen={menuOpen}
          menuOnClick={this.onMenuClick}
        />
        <HomeMenu
          show={menuOpen}
          menu={HOME_MENU}
          history={history}
          toggleModal={this.toggleModal}
        />
        <div>{children}</div>
        <HelpModal show={showModal} closeModal={this.toggleModal} />
      </div>
    );
  }
}

export default StepsWrapper;
